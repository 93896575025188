<script setup lang="ts">
import {useUiNotifications} from "~/stores/ui-notifications";
import {storeToRefs} from "pinia";

const {notifications} = storeToRefs(useUiNotifications())

const {dismissUiNotification} = useUiNotifications()
const fakeOpen = ref(true)
</script>
<template>
  <div id="snackbar-container">
    <template v-for="(notification, index) in notifications" :key="notification.id">
      <v-snackbar
          :color="notification.type"
          multi-line
          v-model="fakeOpen"
          location="top"
          :style="`top: ${index * 80}px`"
      >
        <p v-for="line of notification.message.split('\n')">{{line}}</p>

        <template v-slot:actions>
          <v-btn
              color="red"
              variant="text"
              @click="dismissUiNotification(notification.id)"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </template>
  </div>

</template>
