<script setup lang="ts">
import {createWorkspace} from "~/calls/workspace";
import {awaitCondition} from "~/utils/await";

const {workspaces} = storeToRefs(useSpacesStore())
const emits = defineEmits<{
  finished: (workspaceId: string, environmentId: string) => void
}>()

const projectName = ref<string>('')
const loading = ref(false)
const doCreateWorkspace = () => {
  if(projectName.value === '') return
  loading.value = true
  createWorkspace(projectName.value, 'Local').then((r) => {
    if (!r.success) return

    return awaitCondition(() => {
      return workspaces.value.some(s => s.identifier === r.result.workspaceId)
    }).then(() => {
      emits('finished', r.result.workspaceId, r.result.environmentId)
    })
  }).finally(() => {
    loading.value = false
  })
}
</script>

<template>
  <div>
    <p class="mt-4 text-center h3">
      Let's connect your application
    </p>
    <div class="px-4">
      <p class="mt-2 text-center">
        We first need to create a Workspace for you.
      </p>
      <p class="text-center">
        What's your project's name?
      </p>

      <v-form @submit.prevent="doCreateWorkspace">
        <v-text-field autofocus v-model="projectName" label="Project Name" class="mt-4" hide-details></v-text-field>
        <p class="text-medium-emphasis mt-1 px-1">
          Want to know which data will be collected?
          <DataExplanationDialog>Learn more about it here</DataExplanationDialog>
          .
        </p>

        <div class="d-flex justify-center mt-4">
          <v-btn class="w-100" type="submit" color="secondary" :disabled="projectName === ''" :loading="loading">
            <ArrowRightIcon class="mr-2"/>
            Continue
          </v-btn>
        </div>
      </v-form>

    </div>
  </div>
</template>
