<script setup lang="ts">
import google from "/images/svgs/google-icon.svg";
import github from "/images/svgs/github-mark.svg";
import githubWhite from "/images/svgs/github-mark-white.svg";
import {useAuthentication} from "~/stores/authentication";
import {storeToRefs} from "pinia";
import {useCustomizerStore} from "~/stores/customizer";

const {login, signup} = useAuthentication()
const {authError} = storeToRefs(useAuthentication())
const customizer = useCustomizerStore();

const dark = computed(() => {
  return customizer.actTheme === "DARK_THEME";
});
const githubIcon = computed(() => {
  if (dark.value) {
    return githubWhite
  } else {
    return github
  }
})
</script>

<template>
  <div class="pa-3">
    <v-row class="h-100vh tower-background">
      <v-col cols="11" lg="8" xl="9" class="d-none d-lg-flex authentication">
        <v-row>
          <v-col offset="4" cols="8" xl="6" class="d-flex align-center">
            <v-sheet elevation="4" class="mt-8 mt-lg-0 transparent-card pa-4" style="max-height: 70vh">
              <div class="d-flex gap-4 align-center">
                <div class="flex-shrink-0">
                  <LogoConsoleIcon/>
                </div>

                <div>
                  <h1 class="font-weight-bold">Your ultimate Axon toolbox</h1>
                  <p class="text-h5 pr-4 text-primary pl-2">
                    Get the most out of Axon Framework and Axon Server
                  </p>
                </div>
              </div>


              <v-divider class="my-4"/>
              <div class="mt-4 px-4">
                <div class="d-flex gap-4 mt-2 align-center">
                  <div class="flex-shrink-0">
                    <DashboardIcon size="84" class="text-secondary" stroke-width="2"/>
                  </div>
                  <div>
                    <p class="font-weight-bold">
                      Introspect Axon applications
                    </p>
                    <p class="text-subtitle-1 mt-1">
                      Real-time insights into Axon application performance
                    </p>
                    <p class="text-subtitle-1 mt-1">
                      Up to 30 days retention of all metric data
                    </p>
                    <p class="text-subtitle-1 mt-1">
                      Advanced metrics not found anywhere else
                    </p>
                  </div>
                </div>
              </div>
              <v-divider class="my-4"/>
              <div class="mt-4 px-4">
                <div class="d-flex gap-4 mt-2 align-center">
                  <div class="flex-shrink-0">
                    <BuildingLighthouseIcon :size="84" class="text-primary" stroke-width="2"/>
                  </div>
                  <div>
                    <p class="font-weight-bold">Active Monitoring</p>
                    <p class="text-subtitle-1">Notifications when problems arise</p>
                    <p class="text-subtitle-1 mt-1">Integration with Slack, Pagerduty, and more</p>
                    <p class="text-subtitle-1 mt-1">Customizable to fit any application</p>
                  </div>
                </div>
              </div>
              <v-divider class="my-4"/>
              <div class="mt-4 px-4">
                <div class="d-flex gap-4 mt-2 align-center">
                  <div class="flex-shrink-0">
                    <ServerCogIcon size="84" class="text-info" stroke-width="2"/>
                  </div>
                  <div>
                    <p class="font-weight-bold">Manage your event processors without coding</p>
                    <p class="text-subtitle-1 mt-1">All actions available directly in Console</p>
                    <p class="text-subtitle-1 mt-1">Dead-Letter Queue insights and processing</p>
                    <p class="text-subtitle-1 mt-1">Automatic scaling and balancing of segments</p>
                  </div>
                </div>
              </div>
              <v-divider class="my-4"/>
              <div class="mt-4 px-4">
                <div class="d-flex gap-4 mt-2 align-center">
                  <div class="flex-shrink-0">
                    <LogoServer :size="84" class="text-primary"/>
                  </div>
                  <div>
                    <p class="font-weight-bold">Set up Axon Server with ease</p>
                    <p class="text-subtitle-1 mt-1">Automatic node discovery and clustering</p>
                    <p class="text-subtitle-1 mt-1">Affordable monthly subscription plans</p>
                    <p class="text-subtitle-1 mt-1">Automated surveillance for monitoring</p>
                  </div>
                </div>
              </div>
              <v-divider class="my-4"/>

              <div class="mt-4 px-4 text-end pr-4 mt-4">
                <p class="text-h5 font-weight-bold">Try all premium features 30 days for free</p>
                <p>Credit card required, cancel anytime</p>
                <p class="text-h6 mt-2">Or use our Free plans at any time, no charge</p>
              </div>
            </v-sheet>
          </v-col>

        </v-row>
      </v-col>
      <v-col cols="12" lg="4" xl="3" class="d-flex align-center justify-center bg-surface elevation-2">
        <div class="mt-5 text-center w-75">

          <div class="d-flex justify-center mb-8">
            <Div class="w-75">
              <LogoConsole/>
            </div>
          </div>

          <v-alert v-if="authError" color="error" class="my-4">
            <p>We were unable to log you in. We received the following error:</p>
            <p class="my-2">{{ authError }}</p>
            <p>Please try again, or contact <a href="https://www.axoniq.io/support-offerings" target="_blank">AxonIQ Support</a></p>
          </v-alert>

          <v-btn @click="signup" color="primary" variant="elevated" elevation="2" size="large" class="w-100"
                 block>
            Sign up
          </v-btn>

          <div class="my-4 mt-8">
            <v-divider>Or sign in with</v-divider>
          </div>

          <div class="d-flex gap-2">
            <v-btn @click="login('google-oauth2')" variant="outlined" class="border text-subtitle-1 flex-grow-1">
              <img :src="google" height="20" alt="google"/>
            </v-btn>

            <v-btn @click="login('github')" variant="outlined" class="border text-subtitle-1 flex-grow-1">
              <img :src="githubIcon" height="20" alt="Github"/>
            </v-btn>

            <v-btn @click="login('Username-Password-Authentication')" variant="outlined"
                   class="border text-subtitle-1 flex-grow-1 align-center">
              <KeyboardIcon :src="github" size="20" alt="Github"/>
            </v-btn>

          </div>

          <div class="mt-16">
            <v-btn
                href="https://www.axoniq.io/products/axoniq-console"
                variant="elevated"
                target="_blank"
                class="mt-sm-0 mt-4 lp-btn-shadow m-btn-full btn-custom-lg d-lg-none"
                size="large"
            >Learn more
            </v-btn
            >
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<style scoped>
.tower-background {
  position: relative;
  background-image: url('/images/login-bg.jpg');
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}

.tower-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: -50%;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1;
}

/* Ensure content of div is on top of the dimming layer */
.tower-background > * {
  position: relative;
  z-index: 2;
}

.transparent-card {
  opacity: 0.95;

  @media (max-height: 1000px) {
    overflow-y: scroll
  }
}
</style>