<script setup lang="ts">


import {useSpacesStore} from "~/stores/spaces";
import {storeToRefs} from "pinia";
import {useAuthentication} from "~/stores/authentication";
import {createWorkspace, deleteWorkspace} from "~/calls/workspace";
import {awaitCondition} from "~/utils/await";
import type {Workspace} from "~/types/spaces.types";
import {finishTutorialForUser} from "~/calls/user";

const {workspacesWithoutDemo: workspaces} = storeToRefs(useSpacesStore());
const {email, refreshUser} = useAuthentication()
const roleInWorkspace = (workspace: Workspace) => workspace?.users?.find(u => u.email === email)?.role
const step = ref<null | string>(workspaces.value.length > 0 ? 'intro_filled' : 'intro_blank')

const loading = ref(true)
const createdWorkspaceId = ref<string | null>(null)
const createdEnvironmentId = ref<string | null>(null)

const saveOnboardingStep = (nextStep: string, workspaceId?: string, environmentId?: string) => {
  if (workspaceId) {
    createdWorkspaceId.value = workspaceId
    localStorage.setItem('onboarding_workspace_id', workspaceId)
  } else {
    createdWorkspaceId.value = null
    localStorage.removeItem('onboarding_workspace_id')
  }
  if (environmentId) {
    createdEnvironmentId.value = environmentId
    localStorage.setItem('onboarding_environment_id', environmentId)
  } else {
    createdEnvironmentId.value = null
    localStorage.removeItem('onboarding_environment_id')
  }
  step.value = nextStep
  localStorage.setItem('onboarding_step', nextStep)
}

if (localStorage.getItem('onboarding_step')) {
  step.value = localStorage.getItem('onboarding_step')!!
  createdWorkspaceId.value = localStorage.getItem('onboarding_workspace_id')!!
  createdEnvironmentId.value = localStorage.getItem('onboarding_environment_id')!!
}

const markTutorialAsFinishedLoading = ref(false)
const markTutorialAsFinished = () => {
  markTutorialAsFinishedLoading.value = true
  return finishTutorialForUser()
      .then((r) => {
        if (!r.success) return
        localStorage.removeItem('onboarding_step')
        localStorage.removeItem('onboarding_workspace_id')
        localStorage.removeItem('onboarding_environment_id')
        return refreshUser()
      })
      .then(() => refreshUser())
      .finally(() => {
        markTutorialAsFinishedLoading.value = false
      })
}

const clusterId = computed(() => {
  if (!createdWorkspaceId.value) return null
  const workspace = workspaces.value.find((w: Workspace) => w.identifier === createdWorkspaceId.value)
  if (!workspace || !workspace.environments.length) return null
  return workspace.environments[0].cluster.clusterId
})

const showReset = computed(() => {
  return step.value === 'demo' || step.value === 'own_application' || step.value === 'instructions'
})


const resetOnboarding = () => {
  if (createdWorkspaceId.value) {
    deleteWorkspace(createdWorkspaceId.value!!).then((r) => {
      if (!r.success) return
      saveOnboardingStep('intro_blank')
    })
  } else {
    saveOnboardingStep('intro_blank')
  }
}

const setupDemoLoading = ref(false)
const setupDemo = async () => {
  if (setupDemoLoading.value) return
  setupDemoLoading.value = true
  if (createdWorkspaceId.value) {
    await deleteWorkspace(createdWorkspaceId.value!!)
  }

  createWorkspace('Bike Rental Demo', 'Demo').then((r) => {
    if (!r.success) return

    createdWorkspaceId.value = r.result.workspaceId
    createdEnvironmentId.value = r.result.environmentId

    // Await until the environment has arrived in local store
    return awaitCondition(() => {
      return workspaces.value.some(s => s.identifier === createdWorkspaceId.value)
    }).then(() => {
      saveOnboardingStep('demo', createdWorkspaceId.value!!, createdEnvironmentId.value!!)
    })
  }).finally(() => {
    setupDemoLoading.value = false
  })
}

onMounted(() => {
  if (createdWorkspaceId.value) {
    // Restored from local storage
    loading.value = false
    return
  }
  const isOwnerOfWorkspace = workspaces.value.filter((w: Workspace) => roleInWorkspace(w) === 'OWNER').length > 0
  if (isOwnerOfWorkspace) {
    // This person doesn't need to see the onboarding
    markTutorialAsFinished()
  } else {
    loading.value = false
  }
})
</script>

<template>
  <InfoScreenContainer>
    <template v-if="loading">
      <v-progress-linear indeterminate color="primary"/>
    </template>
    <template v-else-if="step == 'intro_filled'">
      <OnboardingLandingWithWorkspaces @introduction="saveOnboardingStep('intro_blank')" @go="markTutorialAsFinished"/>
    </template>

    <template v-else-if="step == 'intro_blank'">
      <OnboardingLanding @info="step='more_info'" @demo="setupDemo" @app="saveOnboardingStep('own_application')"/>
    </template>

    <!-- More Info, step 1: Product intro -->
    <template v-else-if="step == 'more_info'">
      <OnboardingIntroduction @finished="saveOnboardingStep('resources')"/>
    </template>
    <!-- More Info, step 2: More resources -->
    <template v-else-if="step === 'resources'">
      <OnboardingResources @own_application="saveOnboardingStep('own_application')" @demo="setupDemo"
                           :loading="setupDemoLoading"/>
    </template>
    <!-- Demo screen -->
    <template v-else-if="step === 'demo'">
      <h2>
        Let's run the demo!
      </h2>
      <Demo :workspace-id="createdWorkspaceId!!" :environment-id="createdEnvironmentId!!" :cluster-id="clusterId"
            :onFinished="markTutorialAsFinished"/>
    </template>

    <!-- Flow to connect own application -->
    <template v-else-if="step == 'own_application'">
      <OnboardingCreateWorkspace @finished="(workspaceId, environmentId) => {
        saveOnboardingStep('instructions', workspaceId, environmentId)
      }"></OnboardingCreateWorkspace>
    </template>

    <template v-else-if="step == 'instructions'">
      <InstructionsFramework :workspace-id="createdWorkspaceId!!"
                             :environment-id="createdEnvironmentId!!"
                             :on-ready="markTutorialAsFinished">
        <template v-slot:intro>
          <p class="mt-4 text-center h3">
            How would you like to install our library?
          </p>
        </template>
      </InstructionsFramework>
    </template>

    <template v-if="showReset">


      <v-divider class="my-4"></v-divider>
      <div class="d-flex justify-center">
        <a href="#" @click.prevent="resetOnboarding()" class="text-primary d-flex align-center text-decoration-none">
          <RepeatIcon class="mr-2"/>
          <span>Take me back to the start</span>
        </a>
      </div>
    </template>
  </InfoScreenContainer>
</template>
