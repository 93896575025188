<script setup lang="ts">
import {userRoleToText} from "~/utils/format";
import type {Workspace} from "~/types/spaces.types";
import {storeToRefs} from "pinia";
import {useSpacesStore} from "~/stores/spaces";
import {useAuthentication} from "~/stores/authentication";

const {email} = useAuthentication()
const {workspaces} = storeToRefs(useSpacesStore());
defineEmits<{
  introduction: () => void
  go: () => void
}>()
const roleInWorkspace = (workspace: Workspace) => workspace?.users?.find(u => u.email === email)?.role

</script>

<template>
  <div>
    <p class="mt-4 text-center h3">
      Welcome to AxonIQ Console!
    </p>
    <div class="px-4">

      <p class="mt-4">
        From the team at AxonIQ we would like to welcome you to our platform. We are excited to have you on board and
        can't wait to show you around.
      </p>

      <p class="mt-4">
        You already have access to the Workspaces below, as you have been added to them by your team.
        Would you like to go there directly, or follow the introduction?
      </p>
    </div>

    <ul class="mt-2">
      <v-table density="compact" fixed-header>
        <thead>
        <tr>
          <th>Workspace Name</th>
          <th>Your Role</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="workspace in workspaces" :key="workspace.id">
          <td>{{ workspace.name }}</td>
          <td>{{ userRoleToText(roleInWorkspace(workspace)) }}
          </td>
        </tr>
        </tbody>
      </v-table>
    </ul>

    <div class="d-flex gap-4 mt-8  justify-space-between">
      <v-btn @click="$emit('introduction')" color="primary">
        <BookIcon class="mr-2"/>
        Follow Introduction
      </v-btn>
      <v-btn @click="$emit('go')" color="secondary">
        <PlayerTrackNextIcon class="mr-2"/>
        Go to the Console
      </v-btn>
    </div>
  </div>
</template>
