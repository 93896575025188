<script setup lang="ts">

import {useAuthentication} from "@/stores/authentication";
import {onMounted, onUnmounted} from "vue";

const auth = useAuthentication()

let interval: any;
onMounted(() => {
  interval = setInterval(() => {
    auth.refreshUser(true)
  }, 1000)
})

onUnmounted(() => {
  clearInterval(interval)
})
</script>

<template>
  <InfoScreenContainer title="Email verification required">
      <p>Thank you for signing up to AxonIQ Console. In order to continue, you need to confirm your email. Please check your e-mail inbox and confirm your email address.</p>

      <p>Once completed, this screen will automatically progress within two seconds.</p>
  </InfoScreenContainer>
</template>
