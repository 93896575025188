export const awaitCondition = (condition: () => boolean) => {
    return new Promise<void>((resolve, reject) => {
        let currentTry = 0
        const interval = setInterval(() => {
            currentTry += 1
            if (condition()) {
                clearInterval(interval)
                resolve()
            } else {
                if (currentTry > 50) {
                    clearInterval(interval)
                    reject(new Error('Timeout'))
                }
            }
        }, 100)
    })
}