<script setup lang="ts">
import {useAuth0} from "@auth0/auth0-vue";
import {useAuthentication} from "@/stores/authentication";
import {formRules} from "~/utils/formRules";
import {ArrowLeftIcon, CheckIcon} from "vue-tabler-icons";
import {registerOrUpdateUser} from "~/calls/user";

const auth0 = useAuth0()
const auth = useAuthentication()

const formIsCompleted = ref(null)
const tocCheck = ref(false)
const firstName = ref(auth0.user.value.given_name)
const lastName = ref(auth0.user.value.family_name)
const email = ref(auth0.user.value.email)
const organization = ref('')
const role = ref('')
const loading = ref(false)
const form = ref(null as any)

const logout = () => {
  auth.logout()
}

const onSubmit = async () => {
  const validation = await form.value.validate()
  if (!validation.valid) {
    return
  }

  loading.value = true
  registerOrUpdateUser(firstName.value, lastName.value, organization.value, role.value, tocCheck.value)
      .then((r) => {
        if (!r.success) return
        return auth.refreshUser();
      })
      .finally(() => {
        loading.value = false
      })
}
</script>
<template>
  <InfoScreenContainer title="Register to AxonIQ Console">
    <v-form v-model="formIsCompleted" ref="form" @submit.prevent="onSubmit">
      <v-row>
        <v-col cols="12">
          <p class="mb-4">Let's finalize your account. We'd like to know your name to personalize your experience.</p>
          <v-text-field class="mb-1" label="First name"
                        :rules="[formRules.required]" maxlength="60" :readonly="loading" v-model="firstName"/>
          <v-text-field class="mb-1" label="Last name"
                        :rules="[formRules.required]" maxlength="60" :readonly="loading" v-model="lastName"/>

          <div class="d-flex gap-2 align-start">
            <div>
              <div>
                <v-checkbox v-model="tocCheck" :rules="[formRules.required]" hide-details/>
              </div>
            </div>
            <div class="pt-2">
              I have read and accept the <a class="text-primary text-decoration-none"
                                            href="https://lp.axoniq.io/axoniq-service-subscription-agreement-terms"
                                            target="_blank">AxonIQ Service Subscription agreement</a> and the <a
                target="_blank"
                class="text-primary text-decoration-none"
                href="https://lp.axoniq.io/axoniq-software-subscription-agreement-terms">AxonIQ Software Subscription
              Terms</a>.
            </div>
          </div>

          <div class="gap-2 align-start">
            For information about how AxonIQ processes your personal data, please review our <a
              class="text-primary text-decoration-none"
              href="https://www.axoniq.io/privacy-policy"
              target="_blank">Privacy Policy</a>.
          </div>

          <div class="d-flex justify-space-between mt-5">
            <v-btn variant="outlined" color="primary" :prepend-icon="ArrowLeftIcon" style="opacity: 0.6"
                   @click="logout">Back
            </v-btn>

            <v-btn color="secondary" :loading="loading" :disabled="!formIsCompleted || loading"
                   :prepend-icon="CheckIcon"
                   type="submit">
              Create Account
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </InfoScreenContainer>
</template>
