<script setup lang="ts">
import {ref} from 'vue';
import {useCustomizerStore} from '@/stores/customizer';
import {storeToRefs} from "pinia";
import {useAuthentication} from "~/stores/authentication";

const customizer = useCustomizerStore();
const showLegal = ref(false);
const router = useRouter()

const {
  showLogin,
} = storeToRefs(useAuthentication())
</script>


<template>
  <v-app id="console-app" :theme="customizer.actTheme" :class="[
        customizer.actTheme,
        'horizontalLayout',
    ]">
    <AuthLoginScreen v-if="showLogin"/>
    <div :class="'flex-grow-1 d-flex flex-column maxWidth'" v-else>
      <Navbar/>
      <v-main class="flex-grow-1 d-flex flex-column">
        <v-container :fluid="true" class="d-flex flex-column flex-grow-1 px-md-8 px-sm-0">
          <BootContainer>
            <NuxtPage/>
          </BootContainer>
        </v-container>
      </v-main>
    </div>
    <NotificationSnackbar/>


    <v-footer absolute app>
      <v-container :fluid="true" class="px-md-8 px-sm-0">
        <div class="d-flex gap-3 justify-space-between">
          <div class="text-subtitle-1 text-decoration-none">Copyright © 2024 AxonIQ BV. All Rights Reserved
          </div>
          <a href="#" @click.prevent.stop="showLegal = true" class="text-subtitle-1 text-decoration-none text-primary">
            Legal documents
          </a>
        </div>
      </v-container>
    </v-footer>

    <InstantDialog v-if="showLegal" :width="600" title="Legal Information" @closed="showLegal = false">
      <v-row>
        <v-col cols="6">
          <div class="text-h6 text-center mb-4">Legal Documents</div>
          <p class="text-center text-body-1"><a
              href="https://lp.axoniq.io/axoniq-service-subscription-agreement-terms"
              class="text-decoration-none text-primary " target="_blank">AxonIQ Service Subscription Agreement
            Terms</a></p>
          <p class="text-center"><a href="https://www.axoniq.io/privacy-policy"
                                    class="text-decoration-none text-primary" target="_blank">Privacy Policy</a></p>
          <p class="text-center"><a href="https://lp.axoniq.io/axoniq-data-processing-addendum"
                                    class="text-decoration-none text-primary" target="_blank">Data Processing Addendum</a>
          </p>
        </v-col>
        <v-col cols="6">
          <div class="text-h6 text-center mb-4">Data Subprocessors</div>
          <p class="text-center"><a href="https://cloud.google.com/privacy/gdpr"
                                    class="text-decoration-none text-primary" target="_blank">Google Cloud</a></p>
          <p class="text-center"><a
              href="https://auth0.com/docs/secure/data-privacy-and-compliance/gdpr"
              class="text-decoration-none text-primary" target="_blank">Auth0</a></p>
          <p class="text-center"><a
              href="https://sentry.io/legal/dpa/"
              class="text-decoration-none text-primary" target="_blank">Sentry</a></p>
          <p class="text-center"><a
              href="https://stripe.com/en-gb-es/legal/dpa"
              class="text-decoration-none text-primary" target="_blank">Stripe</a></p>
        </v-col>
      </v-row>
    </InstantDialog>
  </v-app>
</template>
