<script setup lang="ts">
import {useAuthentication} from "~/stores/authentication";
import {storeToRefs} from "pinia";
import {useRouter} from "vue-router";
import {useSpacesStore} from "~/stores/spaces";
import {useApplicationState} from "~/stores/application-state";

const {
  showRegistration,
  showShouldConfirmEmailScreen,
  userTutorialCompleted,
} = storeToRefs(useAuthentication())

const {error, isLoading} = storeToRefs(useApplicationState())

const {workspaces} = storeToRefs(useSpacesStore());
const router = useRouter()
</script>

<template>
  <BootConnectionLoadingScreen v-if="isLoading"/>

  <AuthUnconfirmedEmailScreen v-else-if="showShouldConfirmEmailScreen"></AuthUnconfirmedEmailScreen>
  <AuthRegistrationScreen v-else-if="showRegistration"></AuthRegistrationScreen>
  <OnboardingFlow v-else-if="!userTutorialCompleted"/>
  <slot v-else></slot>
</template>