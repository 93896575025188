<script setup lang="ts">

import {storeToRefs} from "pinia";
import {useApplicationState} from "~/stores/application-state";
import {PlugXIcon} from "vue-tabler-icons";

const {phase, error, loadPercentage} = storeToRefs(useApplicationState())

const phaseToText = computed(() => {
  switch (phase.value) {
    case 'INITIAL':
      return 'Setting things up!';
    case 'LOADING_AUTH_PROVIDER':
      return 'Loading authentication provider';
    case 'ESTABLISHING_CONNECTION':
      return 'Establishing super-fast R(S)ocket connection';
    case 'LOADING_USER':
      return 'Loading your user details';
    case 'LOADING_WORKSPACES':
      return 'Loading your workspaces';
    case 'READY':
      return 'Ready!';
    default:
      return '';
  }
})

</script>

<template>
  <InfoScreenContainer title="Lost connection" class="text-center" v-if="phase === 'CONNECTION_LOST'">
    <p v-if="error" class="text-start">
      <v-alert type="error" class="mb-8" :icon="PlugXIcon">{{ error }}</v-alert>
    </p>

    <p>
      We have lost the connection to AxonIQ Console. Please check your internet connection and try again.
    </p>
  </InfoScreenContainer>
  <InfoScreenContainer title="Welcome to AxonIQ Console" class="text-center" v-else>
    <p v-if="error" class="text-start">
      <v-alert type="error" class="mb-8" :icon="PlugXIcon">
        <p>{{ error }}</p>
        <p class="mt-4">We are trying to recover from the error automatically. If this screen persists, please refresh or contact AxonIQ.</p>
      </v-alert>
    </p>
    <p class="mb-4">We are loading AxonIQ Console for you!</p>
    <v-progress-linear :model-value="loadPercentage" color="secondary" height="15" rounded stream></v-progress-linear>
    <p class="mt-4">{{ phaseToText }}</p>

  </InfoScreenContainer>
</template>